import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function usePassengerList() {
    const headers = [
        { text: '昵称', value: 'nickname' },
        { text: '姓名', value: 'real_name' },
        { text: '原因', value: 'reason' },
        { text: '更新时间', value: 'update_at' },
        { text: '创建时间', value: 'create_at' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false
    })
    const fetchPassengerList = () => {
        table.value.loading = true
        store.dispatch('app-user-passenger-list/fetchBlacklistList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const unBlack = (item) => {
        table.value.loading = true
        console.log(item);
        const data = {
            id: item.id
        }
        store.dispatch('app-user-passenger-list/blacklistRemove', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            fetchPassengerList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误` + error } }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchPassengerList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        unBlack,
        fetchPassengerList
    }
}
