<template>
    <div id='user-list'>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <!-- <v-card-text class='d-flex align-center flex-wrap pb-0'> -->
            <!-- <v-text-field v-model='param.query' class='user-search me-3 mb-4' dense hide-details outlined
                    placeholder='搜索'></v-text-field>
                <v-spacer></v-spacer> -->
            <!-- </v-card-text> -->
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total' show-select>
                <!-- actions -->
                <template #[`item.actions`]='{ item }'>
                    <v-btn @click="unBlack(item)" color="info" small>取消拉黑</v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import passengerStoreModule from '../passengerStoreModule'
import useBlacklist from './useBlacklist'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user-passenger-list'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, passengerStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, unBlack, fetchPassengerList } = useBlacklist()

        fetchPassengerList()

        return {
            headers,
            param,
            table,
            unBlack,
            fetchPassengerList,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
